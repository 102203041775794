import React, { memo, useCallback, useMemo, useState } from 'react'
import { History } from 'history'
import { Dropdown, Text, Icon, Flex } from '@r1/ui-kit'
import { CustomIcon, IssueFeedbackModal, getDataTestId } from '@r1/wireframe-primary'
import { httpClient } from '../../../../shared/lib/httpClient'
import { redirect } from './utils'

type Props = {
  browserHistory: History
  pagePrefixUrl: string
}

const SEND_ISSUE_FEEDBACK = 'SEND_ISSUE_FEEDBACK'
const MY_ISSUES = 'MY_ISSUES'
const SITE_MAP = 'SITE_MAP'

const DEFAULT_DROPDOWN_ITEMS = [
  {
    id: SEND_ISSUE_FEEDBACK,
    title: <Text>Report issue / Feedback</Text>,
    disabled: false,
  },
  {
    id: MY_ISSUES,
    title: (
      <Flex minWidth={1} justify="space-between">
        <Text>My Issues</Text> <Icon type="openInNewTab" />
      </Flex>
    ),
    disabled: false,
  },
  {
    id: SITE_MAP,
    title: <Text>Site Map</Text>,
    disabled: false,
  },
]

export const HelpMenu = memo<Props>(({ browserHistory, pagePrefixUrl }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const toggleModal = useCallback(() => setModalOpen(prev => !prev), [])

  const handleOpenMyIssues = useCallback(() => {
    window.open('https://support.r1ss.com/servicedesk/customer/user/requests')
  }, [])

  const handleOpenSiteMap = useCallback(() => {
    redirect(`${pagePrefixUrl}/sitemap`, browserHistory)
  }, [browserHistory, pagePrefixUrl])

  const onSelectItem = useCallback(
    function onSelectItem(id: string) {
      switch (id) {
        case SEND_ISSUE_FEEDBACK: {
          toggleModal()
          break
        }
        case MY_ISSUES: {
          handleOpenMyIssues()
          break
        }
        case SITE_MAP: {
          handleOpenSiteMap()
          break
        }
        default:
      }
    },
    [toggleModal, handleOpenMyIssues, handleOpenSiteMap],
  )

  const dropdownItems = useMemo(() => DEFAULT_DROPDOWN_ITEMS, [])

  return (
    <div>
      <Dropdown.Menu
        mode="click"
        placement="bottom-end"
        size="S"
        zIndex={51}
        items={dropdownItems}
        data-test-id={getDataTestId('header', 'help-drop-down-menu')}
        onSelectItem={onSelectItem}
      >
        <CustomIcon type="dots/horizontal" dataTestId={getDataTestId('header', 'help-menu-btn')} />
      </Dropdown.Menu>

      <IssueFeedbackModal httpClient={httpClient} show={isModalOpen} onClose={toggleModal} />
    </div>
  )
})
