/* eslint-disable @typescript-eslint/no-explicit-any */
import { Effect, restore } from 'effector-root'

export const createEffectStates = <Error>(fx: Effect<any, any, Error>) => {
  const $errors = restore(fx.failData, null).reset(fx)

  return {
    $pending: fx.pending,
    $errors,
  }
}
