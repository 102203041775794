import { lazy } from 'react'

import {
  ACCESS_DENIED_PAGE_LINK,
  HOME_PAGE,
  PRODUCT_CATALOG,
  SALES,
  SITE_MAP_PAGE_LINK,
} from '../shared/config/routes'
import { RouteType } from '../shared/lib/routing'

const HomePage = lazy(() => import('./HomePage'))
const ProductCatalogModule = lazy(() => import('./ProductCatalogModule'))
const SalesModule = lazy(() => import('./SalesModule'))
const AccessDeniedPage = lazy(() => import('./ErrorPagesContainer/AccessDeniedPage'))
const SiteMapPage = lazy(() => import('./SiteMap'))

export const routes: Array<RouteType> = [
  {
    path: HOME_PAGE,
    component: HomePage,
  },
  {
    path: `${HOME_PAGE}${PRODUCT_CATALOG}/*`,
    component: ProductCatalogModule,
  },
  {
    path: `${HOME_PAGE}${SALES}/*`,
    component: SalesModule,
  },
  {
    path: ACCESS_DENIED_PAGE_LINK,
    component: AccessDeniedPage,
  },
  {
    path: SITE_MAP_PAGE_LINK,
    component: SiteMapPage,
  },
]
