import React, { memo, useCallback } from 'react'
import { Dropdown, Text } from '@r1/ui-kit'
import { CustomIcon, getDataTestId } from '@r1/wireframe-primary'

import { useNavigate } from '../../../shared/lib/routing'
import { SITE_MAP_PAGE_LINK } from '../../../shared/config/routes'

const SITE_MAP = 'SITE_MAP'

const DEFAULT_DROPDOWN_ITEMS = [
  {
    id: SITE_MAP,
    title: <Text>Site Map</Text>,
    disabled: false,
  },
]

export const HelpMenu = memo<object>(() => {
  const navigate = useNavigate()

  const handleOpenSiteMap = useCallback(() => {
    navigate(SITE_MAP_PAGE_LINK)
  }, [navigate])

  const onSelectItem = useCallback(
    (id: string) => {
      switch (id) {
        case SITE_MAP: {
          handleOpenSiteMap()
          break
        }
        default:
      }
    },
    [handleOpenSiteMap],
  )

  return (
    <div>
      <Dropdown.Menu
        mode="click"
        placement="bottom-end"
        size="S"
        zIndex={51}
        items={DEFAULT_DROPDOWN_ITEMS}
        data-test-id={getDataTestId('header', 'help-drop-down-menu')}
        onSelectItem={onSelectItem}
      >
        <CustomIcon type="dots/horizontal" dataTestId={getDataTestId('header', 'help-menu-btn')} />
      </Dropdown.Menu>
    </div>
  )
})
