import { sample, Event, Effect } from 'effector'

export type WithAbortCtrl<T = void> = {
  controller: AbortController
  params: T
}

/**
 * Add ability to abort request
 * @param trigger {Event<T>} - triggers request
 * @param request {Effect<WithAbortCtrl<T>, any, any>} - controllable request
 * @param abort {Event<void>} - aborts request
 *
 * @example
 * const trigger = createEvent<{ id: string }>()
 * const abort = createEvent<any>()
 * const getEntityFx = createEffect(({ id, controller }: WithAbortCtrl<{ id: string }>) => {
 *   return api.getEntity({ pathParams: { params.id } }, originalRequestConfig => ({
 *     ...originalRequestConfig,
 *     signal: controller.signal,
 *   }))
 * })
 */
export function withAbortController<T>(
  trigger: Event<T>,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // it doesn't matter what type for both Done or Error should be
  request: Effect<WithAbortCtrl<T>, any, any>,
  abort: Event<any>,
) {
  sample({
    clock: trigger,
    fn: params => ({
      params,
      controller: new AbortController(),
    }),
    target: request,
  })

  sample({
    clock: abort,
    source: request,
  }).watch(({ controller }) => {
    controller.abort()
  })
}
