import { useContext } from 'react'
import { PermissionsMap } from './permissions'
import { PermissionsProviderContext } from './PermissionsProvider'

export const useAccessControl = (): string[] => {
  const { permissions } = useContext(PermissionsProviderContext)

  if (!permissions) {
    return []
  }

  return Object.keys(permissions).filter(p => permissions[p as keyof PermissionsMap])
}
