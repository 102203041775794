import React, { useEffect, createContext, useState, useMemo } from 'react'

import { AccessDeniedPage } from '../../../pages/ErrorPagesContainer/AccessDeniedPage/AccessDeniedPage'
import { PERMISSIONS, PermissionsMap } from './permissions'

type Context = {
  permissions: PermissionsMap | null
  page403Renderer: () => React.ReactElement
}

type PermissionsProviderProps = {
  userPermissions: (keyof PermissionsMap)[]
  children: React.ReactNode
}

const defaultPage403Renderer = () => <AccessDeniedPage />

const defaultContext: Context = {
  permissions: PERMISSIONS,
  page403Renderer: defaultPage403Renderer,
}

export const PermissionsProviderContext: React.Context<Context> = createContext(defaultContext)

export const PermissionsProvider = ({ userPermissions, children }: PermissionsProviderProps) => {
  const [permissions, setPermissions] = useState<PermissionsMap | null>(null)

  useEffect(() => {
    const newPermissions = { ...defaultContext.permissions }
    userPermissions.forEach((permission: keyof PermissionsMap) => {
      newPermissions[permission] = true
    })
    setPermissions(newPermissions)

    return () => {
      setPermissions(defaultContext.permissions)
    }
  }, [userPermissions])

  const contextValue = useMemo(() => ({ ...defaultContext, permissions }), [permissions])

  return permissions ? (
    <PermissionsProviderContext.Provider value={contextValue}>
      {children}
    </PermissionsProviderContext.Provider>
  ) : null
}
