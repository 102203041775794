import * as React from 'react'

type Context = {
  isFergusonOnly: boolean
}

type FeatureFlagsProviderProps = React.PropsWithChildren<{
  flags: Context
}>

export type Flags = Array<keyof Context>

const defaultContext: Context = {
  isFergusonOnly: false,
}

export const FeatureFlagsProviderContext: React.Context<Context> =
  React.createContext(defaultContext)

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
  const contextValue = React.useMemo(() => ({ ...defaultContext, ...flags }), [flags])

  return (
    <FeatureFlagsProviderContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsProviderContext.Provider>
  )
}
