/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-call */
import { httpClient } from '.'

export const redirect = (url: string) => {
  window.location.href = url
}

export const redirectToLogin = () => {
  const { pathname, search, hash } = window.location
  const path = pathname + search + hash
  let queryString = ''

  if (path !== '/') {
    queryString = `?path=${encodeURIComponent(path)}`
  }

  if (pathname !== '/login') {
    redirect(`/login${queryString}`)
  }
}

// Remove temp keys (excluding specified in get parameters) from localStorage
const clearLocalStorage = () => {
  const keyPrefix = 'temp_'
  const keyRegex = new RegExp(`${keyPrefix}[0-9a-f]{32}`, 'gi')
  const keys = window.location.search.match(keyRegex)
  const excludedKeys = new Set<string>()
  if (keys) {
    keys.forEach(key => excludedKeys.add(key))
  }

  Object.keys(localStorage)
    .filter(key => key.startsWith(keyPrefix) && !excludedKeys.has(key))
    .forEach(key => localStorage.removeItem(key))
}

export const handleLogout = () => {
  // TODO: extend axios type
  // @ts-ignore
  httpClient.logout()
  clearLocalStorage()
  redirectToLogin()
}
