import React, { useCallback, useEffect } from 'react'
import { WireframeProvider } from '@r1/wireframe-primary'
import { createBrowserHistory } from 'history'

import { httpClient } from '../../../shared/lib/httpClient'
import { handleLogout } from '../../../shared/lib/httpClient/handleLogout'
import {
  INavigationItemWithRequiredPermissions,
  navigationApi,
  navigationApiFergusonOnly,
} from '../../../shared/api/NavigationApiMock'
import { useAccessControl } from '../../../shared/providers/PermissionsProvider'
import { useActiveFlags } from '../../../shared/providers/FeatureFlagsProvider'
import { HelpMenu } from './HelpMenu'

export const history = createBrowserHistory({ window })

// Remove this when localStorage usage will be remove from the NavigationStateProvider at wireframe-primary
function clearWireframeLocalStorage() {
  Object.keys(localStorage).forEach(key => {
    // reset wireframe local storage after two months
    if (key.includes('Wireframe:')) localStorage.removeItem(key)
  })
}

function renderHeaderRightSide() {
  return <HelpMenu />
}

export const WireframeProviderCreator = ({ children }: { children: React.ReactNode }) => {
  const onLogout = useCallback(() => {
    handleLogout()
  }, [])
  const allowedPermissions = useAccessControl()
  const isFergusonOnly = useActiveFlags(['isFergusonOnly'])

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    if (window.APP_ENVIRONMENT === 'saas') {
      clearWireframeLocalStorage()
    }
  }, [])

  const getMenuItems = async (programId: number | null) => {
    const currentNavigationApi = isFergusonOnly ? navigationApiFergusonOnly : navigationApi
    const result: INavigationItemWithRequiredPermissions[] =
      await currentNavigationApi.getMenuItems(programId)

    return result.filter(item => {
      // if menuItem permissions isn't set or empty - menu item will be rendered
      if (!item.permissions || !item.permissions.length) {
        return true
      }

      // if user has any of menuItem permission - menu item will be rendered
      return item.permissions.some(permission => allowedPermissions.includes(permission))
    })
  }

  return (
    <WireframeProvider
      key={allowedPermissions.length}
      withSearch="Pages"
      userAccount={{ id: 'User', login: 'User', fullName: 'User', email: '' }}
      browserHistory={history}
      httpClient={httpClient}
      navigationApi={{ ...navigationApi, getMenuItems }}
      renderHeaderRightSide={renderHeaderRightSide}
      onLogout={onLogout}
    >
      {children}
    </WireframeProvider>
  )
}
