import { History } from 'history'

const shouldUseBrowserHistory = (nextPathName: string) => {
  const isSpaPath = (pathName: string) => /^\/r1(v2)?\//.test(pathName)
  return isSpaPath(window.location.pathname) && isSpaPath(nextPathName)
}

export const redirect = (nextPathName: string, browserHistory?: History) => {
  if (browserHistory && shouldUseBrowserHistory(nextPathName)) {
    browserHistory.push(nextPathName)
  } else {
    window.location.href = nextPathName
  }
}
