import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Dropdown, Text, Link, Flex } from '@r1/ui-kit'
import { OperatorTemplateInfo } from '@r1-webui/usermanagement-v1'
import {
  SetupPrinterModal,
  WorkstationModal,
  CustomIcon,
  getDataTestId,
} from '@r1/wireframe-primary'
import { handleServerError, useAccessControl } from '@r1/core-blocks'
import { httpClient } from '../../../../shared/lib/httpClient'

const SETUP_PRINTER = 'SETUP PRINTER'
const SETUP_WORKSTATION = 'SETUP WORKSTATION'
const PRINT_GATEWAY = 'PRINT GATEWAY'

const DEFAULT_ITEMS = [
  {
    id: SETUP_PRINTER,
    title: <Text>Printer Setup</Text>,
  },
  {
    id: SETUP_WORKSTATION,
    title: <Text>Workstation Setup</Text>,
  },
]

const LinkContainer = styled(Flex)`
  > a {
    text-decoration: none;
    color: ${props => props.theme.palette.grey[800]} !important;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.palette.grey[800]} !important;
    }
  }
`

export const SettingsMenu = () => {
  const [isSetupPrinterModalOpen, setSetupPrinterModalOpen] = useState<boolean>(false)
  const [isWorkstationModalOpen, setWorkstationModalOpen] = useState<boolean>(false)
  const toggleSetupPrinterModal = useCallback(() => setSetupPrinterModalOpen(prev => !prev), [])
  const toggleWorkstationModal = useCallback(() => setWorkstationModalOpen(prev => !prev), [])

  const [{ allowWmsPrintGatewayView }] = useAccessControl()

  const onSelectItem = useCallback(
    (id: string) => {
      switch (id) {
        case SETUP_PRINTER: {
          toggleSetupPrinterModal()
          break
        }
        case SETUP_WORKSTATION: {
          toggleWorkstationModal()
          break
        }
        default:
      }
    },
    [toggleSetupPrinterModal, toggleWorkstationModal],
  )

  const items = useMemo(() => {
    if (allowWmsPrintGatewayView) {
      return [
        ...DEFAULT_ITEMS,
        {
          id: PRINT_GATEWAY,
          title: (
            <LinkContainer>
              <Link to="/wms-pfms/print-gateway">Print Gateway</Link>
            </LinkContainer>
          ),
        },
      ]
    }

    return DEFAULT_ITEMS
  }, [allowWmsPrintGatewayView])

  const getActiveOperatorTemplatesInfo = useCallback(async () => {
    const response = await httpClient.get('uisvc/user-management/users/public/operator-templates')
    if (response.status !== 200) {
      handleServerError(response)
    }

    if (response.data) {
      return response.data as OperatorTemplateInfo[]
    }

    return response as unknown as OperatorTemplateInfo[]
  }, [])

  return (
    <div>
      <Dropdown.Menu
        mode="click"
        placement="bottom-end"
        size="S"
        zIndex={51}
        items={items}
        data-test-id={getDataTestId('header', 'help-drop-down-menu')}
        onSelectItem={onSelectItem}
      >
        <CustomIcon type="tools" dataTestId={getDataTestId('header', 'settings-menu-btn')} />
      </Dropdown.Menu>
      <SetupPrinterModal isOpen={isSetupPrinterModalOpen} onClose={toggleSetupPrinterModal} />
      <WorkstationModal
        getActiveOperatorTemplatesInfo={getActiveOperatorTemplatesInfo}
        show={isWorkstationModalOpen}
        onClose={toggleWorkstationModal}
      />
    </div>
  )
}
