import type {
  INavigationApiService,
  INavigationItem,
} from '@r1/wireframe-primary/typings/WireframeProvider/types/NavigationApiService'
import { Catalog } from '@r1-oas/catalogs-queries'
import {
  ATTRIBUTES_PAGE_LINK,
  CATALOGS_PAGE_LINK,
  CATEGORIES_PAGE_LINK,
  DATA_ENRICHMENT_SETTING_LINK,
  HOME_PAGE,
  INVENTORY_PAGE_LINK,
  LISTING_RULES_PAGE_LINK,
  MARKETPLACE_ACCOUNTS_PAGE_LINK,
  ORDER_PAGE_LINK,
  PALLETS_PAGE_LINK,
  PRODUCT_CATALOG,
  EXTERNAL_DATA_RULES_PAGE_LINK,
} from '../../config/routes'
import { PermissionsMap } from '../../providers/PermissionsProvider/permissions'
import { humanizeString } from '../../lib/humanizeString'
import { CatalogsQueriesApi } from '../CatalogsQueriesApi'
import { isFergusonCatalogName } from '../../lib/isFergusonCatalogName'

export type INavigationItemWithRequiredPermissions = {
  permissions?: (keyof PermissionsMap)[]
} & INavigationItem

export type INavigationApiServiceWithRequiredPermissions = Pick<
  INavigationApiService,
  'addToStarred' | 'deleteFromStarred'
> & {
  getMenuItems: (programId: number | null) => Promise<Array<INavigationItemWithRequiredPermissions>>
}

const MAIN_CATALOG_ID = '110133dd-7f6d-4e69-bbee-7ce0b9399f76'
const mockMainCatalog: Catalog = {
  id: MAIN_CATALOG_ID,
  isMain: true,
  isDraft: false,
  name: 'Main',
  templatesGridId: '',
}

const PRODUCT_CATALOG_ID = '8'
const CATALOGS_ID = 'catalogs'
const EXTERNAL_DATA_RULES_ID = 'external-data-rules'

const menuItemsMock: INavigationItemWithRequiredPermissions[] = [
  {
    id: 'home',
    title: 'Home Page',
    description: 'Home Page',
    url: HOME_PAGE,
    iconSvgId: 'home',
  },
  {
    id: PRODUCT_CATALOG_ID,
    title: 'Product Catalog',
    // permissions: ['catalog:create'],
    description: 'Product Catalog Pages',
    iconSvgId: 'catalog',
  },
  {
    id: CATALOGS_ID,
    parentId: PRODUCT_CATALOG_ID,
    title: 'Catalogs',
    description: 'Main and secondary catalogs',
    url: CATALOGS_PAGE_LINK,
    isStarred: false,
  },
  {
    id: 'sales',
    title: 'Sales',
    description: 'Sales Pages',
    iconSvgId: 'sales',
  },
  {
    id: 'marketplace-accounts',
    parentId: 'sales',
    title: 'Marketplace Accounts',
    url: MARKETPLACE_ACCOUNTS_PAGE_LINK,
    isStarred: false,
  },
  {
    id: 'inventory',
    parentId: 'sales',
    title: 'Finished Goods',
    description: 'Finished Goods',
    url: INVENTORY_PAGE_LINK,
    isStarred: false,
  },
  {
    id: 'pallets',
    parentId: 'sales',
    title: 'As-Is Pallets',
    url: PALLETS_PAGE_LINK,
    isStarred: false,
  },
  {
    id: 'listing-rules',
    parentId: 'sales',
    title: 'Listing Rules',
    description: 'Listing Rules Grid',
    url: LISTING_RULES_PAGE_LINK,
    isStarred: false,
  },
  {
    id: 'orders',
    parentId: 'sales',
    title: 'Sales Orders',
    // permissions: ['B2B Order:Edit'],
    url: ORDER_PAGE_LINK,
    isStarred: false,
  },
  // Uncomment after EDE permissions will be added on the backend
  // {
  //   id: EXTERNAL_DATA_RULES_ID,
  //   parentId: PRODUCT_CATALOG_ID,
  //   title: 'External Data Rules',
  //   description: 'External Data Rules',
  //   url: EXTERNAL_DATA_RULES_PAGE_LINK,
  //   isStarred: false,
  // },
]

function generateMainCatalogMenuSection(
  catalog: Catalog,
): INavigationItemWithRequiredPermissions[] {
  return [
    {
      id: catalog.id,
      parentId: CATALOGS_ID,
      title: humanizeString(catalog.name),
      description: 'Main catalog',
      isStarred: false,
    },
    {
      id: 'attributes',
      parentId: catalog.id,
      title: 'Attributes',
      description: 'Set up attributes for products',
      url: ATTRIBUTES_PAGE_LINK,
      isStarred: false,
    },
    {
      id: 'categories',
      parentId: catalog.id,
      title: 'Categories',
      description: 'Categories List',
      url: CATEGORIES_PAGE_LINK,
      isStarred: false,
    },
    {
      id: `${catalog.id}-families`,
      parentId: catalog.id,
      title: 'Families',
      description: '',
      url: `${HOME_PAGE}${PRODUCT_CATALOG}/catalogs/${catalog.id}/families`,
      isStarred: false,
    },
    {
      id: 'templates',
      parentId: catalog.id,
      title: 'Templates',
      description: 'Templates of product catalog',
      url: `${HOME_PAGE}${PRODUCT_CATALOG}/catalogs/${catalog.id}/templates`,
      isStarred: false,
    },
    {
      id: 'data-enrichment',
      parentId: catalog.id,
      title: 'Data Enrichment',
      description: 'Data Enrichment of main catalog',
      url: DATA_ENRICHMENT_SETTING_LINK,
      isStarred: false,
    },
  ]
}

function generateSecondaryCatalogMenuSection(
  secondaryCatalog: Catalog,
  parentId = CATALOGS_ID,
): INavigationItemWithRequiredPermissions[] {
  return [
    {
      id: secondaryCatalog.id,
      parentId,
      title: humanizeString(secondaryCatalog.name),
      description: secondaryCatalog.name,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-families`,
      parentId: secondaryCatalog.id,
      title: 'Families',
      description: '',
      url: `${HOME_PAGE}${PRODUCT_CATALOG}/catalogs/${secondaryCatalog.id}/families`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-templates`,
      parentId: secondaryCatalog.id,
      title: 'Templates',
      description: '',
      url: `${HOME_PAGE}${PRODUCT_CATALOG}/catalogs/${secondaryCatalog.id}/templates`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-sftp-connections`,
      parentId: secondaryCatalog.id,
      title: 'SFTP connections',
      description: '',
      url: `${HOME_PAGE}${PRODUCT_CATALOG}/catalogs/${secondaryCatalog.id}/sftp-connections`,
      isStarred: false,
      permissions: ['CatalogImportSFTPSettings:View'],
    },
  ]
}

export const navigationApi: INavigationApiServiceWithRequiredPermissions = {
  getMenuItems: async () => {
    const catalogs = await CatalogsQueriesApi.getCatalogs()
    if (catalogs.success) {
      return catalogs.body.reduce<Array<INavigationItemWithRequiredPermissions>>(
        (menuItems, catalog) => {
          if (catalog.isDraft) return menuItems
          if (catalog.isMain) {
            const mainMenuItem = generateMainCatalogMenuSection(catalog)
            menuItems.unshift(...mainMenuItem)
          } else {
            const secondaryMenuItem = generateSecondaryCatalogMenuSection(catalog)
            menuItems.push(...secondaryMenuItem)
          }
          return menuItems
        },
        menuItemsMock,
      )
    }

    return menuItemsMock.concat(generateMainCatalogMenuSection(mockMainCatalog))
  },
  addToStarred: () => Promise.resolve(undefined),
  deleteFromStarred: () => Promise.resolve(undefined),
}

export const navigationApiFergusonOnly: INavigationApiServiceWithRequiredPermissions = {
  getMenuItems: async () => {
    const catalogs = await CatalogsQueriesApi.getCatalogs()
    if (catalogs.success) {
      const fergusonCatalog = catalogs.body.find(({ name }) => isFergusonCatalogName(name))
      const fergusonCatalogMenuItem = fergusonCatalog
        ? generateSecondaryCatalogMenuSection(fergusonCatalog, PRODUCT_CATALOG_ID)
        : []

      const defaultMenuItems = menuItemsMock.filter(
        ({ parentId }) => parentId !== PRODUCT_CATALOG_ID,
      )

      const menuItems = [...defaultMenuItems, ...fergusonCatalogMenuItem]

      return menuItems
    }

    return menuItemsMock.concat(generateMainCatalogMenuSection(mockMainCatalog))
  },
  addToStarred: () => Promise.resolve(undefined),
  deleteFromStarred: () => Promise.resolve(undefined),
}
