/* eslint-disable @typescript-eslint/unbound-method */

import { createEvent, createStore, forward } from 'effector-root'
import { persist } from 'effector-storage/local'

import { UserService, AuthService, User } from '../../../shared/api/AuthApiMock'
import { createEffectStates, createRequest } from '../../../shared/lib/effector'

const fetchUserFx = createRequest(UserService.getCurrentUser)
const logoutFx = createRequest(AuthService.signOut)

const logout = createEvent<unknown>()
const setLoggedIn = createEvent()

const $loggedIn = createStore(false)
const $user = createStore<User | null>(null)
const fetchUserStates = createEffectStates(fetchUserFx)

$user.on(fetchUserFx.doneData, (_, user) => user).reset(logout)
$loggedIn.on(setLoggedIn, () => true).reset(logout)

persist({
  store: $loggedIn,
  key: 'userLoggedIn',
})

persist({
  store: $user,
  key: '$user',
})

forward({
  from: logout,
  to: logoutFx,
})

export { fetchUserFx, fetchUserStates, $user, logout, setLoggedIn, $loggedIn }
