import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './app'

const container = document.getElementById('react')

// TODO: TEMPORARY SOLUTION - REMOVE WHEN STAGING WILL EXIST
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.APP_ENVIRONMENT = /(rc\d\dwb|therecongroup).*/g.test(window.location.host) ? 'r1' : 'saas'

const renderApp = () => {
  if (container) {
    ReactDOM.render(<App />, container)
  }
}

renderApp()
