/* eslint-disable @typescript-eslint/require-await */
import { withAuth, cookie, saveUserSession } from '@r1/http-client'

const INVALID_TOKEN = '00000000000000000000000000000000'

export const authMiddleware = withAuth({
  defaultTokenValues: {
    refreshToken: INVALID_TOKEN,
    accessToken: INVALID_TOKEN,
  },
  tokenMappers: {
    access: response => {
      // @todo: there is a typing error in http-client
      return (response as unknown as Record<string, string>).accessToken
    },
  },
  urls: {
    refreshToken: '/wms-pfms/api/authservice/v1/Session/create',
    accessToken: '/wms-pfms/api/authservice/v1/Session/refresh',
  },
  // eslint-disable-next-line
  getTokens: async (url = '') => {
    let accessToken = ''

    if (url.match(/^\/uisvc.*$/) || url.match(/^\/ui\/api.*$/)) {
      accessToken = cookie.getItem('thorAccessToken') || INVALID_TOKEN
    } else {
      accessToken = cookie.getItem('accessToken') || INVALID_TOKEN
    }

    const refreshToken = cookie.getItem('refreshToken') || INVALID_TOKEN

    return Promise.resolve({
      accessToken,
      refreshToken,
    })
  },
  setSession: async session => {
    // eslint-disable-next-line
    await saveUserSession(session ?? {})
  },
})
