import React from 'react'
import { NotificationSystem } from '@r1/ui-kit'

export type Notification = {
  message?: React.ReactChild
  title?: string | number
  children?: React.ReactNode
  level?: 'success' | 'error' | 'warning' | 'info'
  autoDismiss?: boolean
  uid?: string
}

export const showNotification = ({ level = 'success', title, message }: Notification) => {
  NotificationSystem.addNotification({
    level,
    title,
    message,
  })
}
