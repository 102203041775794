import React, { useCallback } from 'react'
import { Link, H2 } from '@r1/ui-kit'
import { Main, ContentHeader } from '@r1/wireframe-primary'
import { Location, NavigateFunction } from 'react-router-dom'

import { CommonErrorContainer, NotFoundSvg } from '../components'

// to avoid cycle-dependency
type Props = {
  navigate: NavigateFunction
  location: Location
}

export const NotFoundPage = ({ navigate, location }: Props) => {
  const redirectToLastPage = useCallback(() => navigate(-1), [navigate])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.APP_ENVIRONMENT === 'r1' && !window.location.pathname.startsWith('/app')) {
    window.location.replace(location.pathname)
    return null
  }
  return (
    <Main fullScreen>
      <ContentHeader.Title browserTabTitle="404 Not Found" />
      <CommonErrorContainer svg={<NotFoundSvg />}>
        <H2>
          We couldn&apos;t find the page you&apos;re looking for.
          <br />
          <Link onClick={redirectToLastPage}>Go back</Link>
          &nbsp;to the previous page or use menu to navigate away.
        </H2>
      </CommonErrorContainer>
    </Main>
  )
}
