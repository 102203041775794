import React, { useContext, useMemo } from 'react'
import { PermissionsMap } from './permissions'
import { PermissionsProviderContext } from './PermissionsProvider'

export type PageAccessControlProps = {
  requiredPermissions: (keyof PermissionsMap)[]
  children: React.ReactElement
  allowedWhen?: 'All' | 'Any'
}

const allowedWhenAll = (allowed: (boolean | undefined)[]) =>
  allowed.every((item: boolean | undefined) => item)
const allowedWhenAny = (allowed: (boolean | undefined)[]) =>
  allowed.some((item: boolean | undefined) => item)

const checkIfPermissionsAllowed = (
  allowedPermissions: (boolean | undefined)[],
  allowedWhen: 'All' | 'Any',
) =>
  !allowedPermissions.length ||
  (allowedWhen === 'All' && allowedWhenAll(allowedPermissions)) ||
  (allowedWhen === 'Any' && allowedWhenAny(allowedPermissions))

export const PageAccessControl = ({
  requiredPermissions,
  children,
  allowedWhen = 'Any',
}: PageAccessControlProps) => {
  const { permissions, page403Renderer } = useContext(PermissionsProviderContext)

  const allowedPermissions = useMemo(() => {
    if (!permissions) return []
    return requiredPermissions.map(permission => permissions[permission])
  }, [requiredPermissions, permissions])

  if (checkIfPermissionsAllowed(allowedPermissions, allowedWhen)) {
    return children
  }

  return page403Renderer()
}
