import React from 'react'

export const AccessDeniedSvg = () => (
  <svg width="885" height="862" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path d="M1016 0H0v1016h1016V0Z" fill="#F6F7F8" />
      <path opacity=".5" d="M374 414h32v-32l-32 32ZM376 318h32v-32l-32 32Z" fill="#D6DADE" />
      <path opacity=".5" d="M278 638h-32v32h32v-32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="M406 478h-32v64h32v-64ZM310 606h-32v32h32v-32ZM374 446h32v-32h-32M310 542l64 64v-64h-64ZM438 606h32v-32l-32 32ZM470 637.8h-32v32l32-32Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M312 158h32v-32l-32 32ZM534 325.8h-32v32l32-32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="m280 510-32 32h32v-32ZM374 606l-32 32h32v-32ZM566 542l-32 32h32v-32ZM502 446l32 32v-32h-32Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M502 606h-32v64h32v-64Z" fill="#E6E8EB" />
      <path opacity=".5" d="M598 574h-32v64h32v-64ZM534 478h-32v32h32v-32Z" fill="#D6DADE" />
      <path opacity=".5" d="M502 414h-32v32h32v-32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="M566 638h-32v32h32v-32ZM534 574l-64-64v64h64ZM566 414l-32 32h32v-32ZM662 638h-32v64h32v-64ZM790 478h-32v64h32v-64ZM696 446h-32v32h32v-32Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M694 606h-32v32h32v-32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="M598 510h-32v32h32v-32ZM694 542l64 64v-64h-64ZM664 286h32v-32l-32 32Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M632 94h32V62l-32 32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="M695.6 446.8h32v-32l-32 32ZM664 510l-32 32h32v-32ZM758 605.8l-32 32h32v-32ZM758 638l32 32v-32h-32ZM632 382l64 64v-64h-64Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M759.6 214.6h-32v32h32v-32Z" fill="#E6E8EB" />
      <path
        opacity=".5"
        d="M758 350h-32v32h32v-32ZM758 445.8h-32v32h32v-32ZM310 446h-32v32h32v-32Z"
        fill="#D6DADE"
      />
      <path opacity=".5" d="M280 286h32v-32l-32 32Z" fill="#E6E8EB" />
      <path opacity=".5" d="M310 446h32v-32l-32 32Z" fill="#D6DADE" />
      <path
        opacity=".5"
        d="m246 382 64 64v-64h-64ZM374 350h-32v32h32v-32ZM374 446h-32v32h32v-32Z"
        fill="#E6E8EB"
      />
      <path
        d="M470 702h96v96h-96v-96Zm0-32-32 32v96l32 32h96l32-32v-96l-32-32h-96ZM374 670v64h-96v-64h-32v64l32 32h96v64h32V670h-32ZM630 670v32h128v32h-96v32h96v32H630v32h128l32-32v-32l-16-16 16-16v-32l-32-32H630Z"
        fill="#16293F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h1016v1016H0z" />
      </clipPath>
    </defs>
  </svg>
)
