import * as React from 'react'
import {
  useBackgroundTasksProvider,
  CustomIcon,
  BackgroundTasks,
  getDataTestId,
  getDataTestState,
} from '@r1/wireframe-primary'
import { httpClient } from '../../../../shared/lib/httpClient'

export const BackgroundTasksContainer = () => {
  const { isBackgroundTasksOpen, showBackgroundTasks, hideBackgroundTasks } =
    useBackgroundTasksProvider()

  return (
    <div>
      <CustomIcon
        type="activity"
        dataTestId={getDataTestId('header', 'export-drawer-button')}
        dataTestState={getDataTestState({ isBackgroundTasksOpen })}
        onClick={showBackgroundTasks}
      />

      <BackgroundTasks
        httpClient={httpClient}
        show={isBackgroundTasksOpen}
        onClose={hideBackgroundTasks}
      />
    </div>
  )
}
