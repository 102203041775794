import React from 'react'

import { useGate, useStore } from 'effector-react'

import { NotificationSystem } from '@r1/ui-kit'
import { routes } from '../pages'

import { Routing } from '../shared/lib/routing'
import { PageLoader } from '../shared/components/PageLoader'

import { AppGate, stores } from './model'
import { Providers } from './providers'

export const App = () => {
  const isAppLoading = useStore(stores.$isAppLoading)
  useGate(AppGate)
  return (
    <Providers>
      <React.Fragment>
        <PageLoader loading={isAppLoading} />
        <NotificationSystem />
        <Routing routes={routes} />
      </React.Fragment>
    </Providers>
  )
}
