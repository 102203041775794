import React from 'react'
import {
  Routes as BaseRoutes,
  RoutesProps,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { NotFoundPage } from '../../../../pages/ErrorPagesContainer/NotFoundPage'
import { NOT_FOUND_PAGE_LINK } from '../../../config/routes'

export const Routes = ({ children, ...props }: RoutesProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <BaseRoutes {...props}>
      {children}
      <Route
        path={NOT_FOUND_PAGE_LINK}
        element={<NotFoundPage navigate={navigate} location={location} />}
      />
      <Route path="*" element={<NotFoundPage navigate={navigate} location={location} />} />
    </BaseRoutes>
  )
}
