import { createGate } from 'effector-react'
import { combine, createEffect, merge, restore, root, sample, createEvent } from 'effector-root'
import Keycloak from 'keycloak-js'

import { $loggedIn, $user, fetchUserFx, fetchUserStates, logout } from '../../entities/user'
import { onRequestFail } from '../../shared/lib/effector'
import { showNotification } from '../../shared/lib/showNotification'
import { getLegacyPermissions } from '../../shared/providers/PermissionsProvider'

const AppGate = createGate()

const $isAppLoading = combine(
  fetchUserStates.$pending,
  $user,
  (userPending, user) => userPending && user === null,
)

const $appLoaded = root
  .createStore(false)
  .on($isAppLoading.updates, (_, isAppLoading) => !isAppLoading)

sample({
  clock: merge([AppGate.open, $loggedIn.updates]),
  filter: $loggedIn,
  target: [fetchUserFx],
})

sample({
  clock: onRequestFail,
  source: $loggedIn,
  filter: (loggedIn, error) => {
    if (!loggedIn) return false
    if (typeof error !== 'object' || error === null) return false

    const errorResponse = error as { status: number }

    return errorResponse.status === 401
  },
  target: logout,
})

onRequestFail.watch(error => {
  const errorResponse = error as { status: number }

  if (errorResponse.status === 401) {
    showNotification({ message: 'You need to be logged in' })
  }

  if (errorResponse.status >= 500) {
    showNotification({ message: 'Something went wrong :(' })
  }
})

root.onCreateStore(store => {
  store.reset(logout)
})

const onAuthSuccess = createEffect((token: Keycloak.KeycloakTokenParsed | undefined) => {
  return token
})

const $token = restore(onAuthSuccess.doneData, null)

// TODO: remove when transfer key-clock
const getPermissions = createEvent()
$token.on(getPermissions, currentStore => {
  const permissionsR1 = getLegacyPermissions()
  const token = currentStore || {}
  return {
    ...token,
    permissions: Object.entries(permissionsR1).reduce<string[]>((acc, [permission, actions]) => {
      const updatedPermission = actions.map(action => `${permission}:${action}`)
      acc.push(...updatedPermission)
      return acc
    }, []),
  }
})

const stores = {
  $isAppLoading,
  $appLoaded,
  $token,
}

const effects = {
  onAuthSuccess,
}

const events = {
  getPermissions,
}

export { AppGate, stores, effects, events }
