/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import { useStore } from 'effector-react'
import { ConfigurationProvider, Link } from '@r1/ui-kit'
import { GenericIconsProvider } from '@r1/core-blocks'

import { stores, events } from '../model'
import { httpClient } from '../../shared/lib/httpClient'
import { HistoryRouter } from '../../shared/lib/routing'
import { PageLoader } from '../../shared/components/PageLoader'
import { PermissionsProvider } from '../../shared/providers/PermissionsProvider'
import { FeatureFlagsProvider } from '../../shared/providers/FeatureFlagsProvider'
import { PermissionsMap } from '../../shared/providers/PermissionsProvider/permissions'

import { UniversalRouterLink } from './UniversalRouterLink'
import { WireframeProviderCreator, history as r1SaasHistory } from './WireframeProviderCreator'
import {
  WireframeProviderCreatorUnified,
  history as unifiedHistory,
} from './WireframeProviderCreatorUnified'

Link.setLinkComponent(UniversalRouterLink)

const flags = {
  // @ts-ignore
  isFergusonOnly: ((FEATURE_FLAGS as string) || '').split(';').includes('IS_FERGUSON_ONLY'),
}

export const Providers = ({ children }: { children: React.ReactNode }) => {
  const token = useStore(stores.$token)

  // @ts-ignore
  const isSaas = window.APP_ENVIRONMENT === 'saas'
  const WFProviderCreator = isSaas ? WireframeProviderCreator : WireframeProviderCreatorUnified
  const history = isSaas ? r1SaasHistory : unifiedHistory

  // TODO: remove when transfer key-clock
  React.useEffect(events.getPermissions, [])

  const permissions = token ? (token.permissions as (keyof PermissionsMap)[]) : []

  return (
    <FeatureFlagsProvider flags={flags}>
      <PermissionsProvider userPermissions={permissions}>
        <ConfigurationProvider>
          <GenericIconsProvider httpClient={httpClient}>
            {/* @ts-ignore */}
            <HistoryRouter history={history}>
              <React.Suspense fallback={<PageLoader loading />}>
                <WFProviderCreator>{children}</WFProviderCreator>
              </React.Suspense>
            </HistoryRouter>
          </GenericIconsProvider>
        </ConfigurationProvider>
      </PermissionsProvider>
    </FeatureFlagsProvider>
  )
}
