export type PermissionsMap = {
  'B2B Order:Edit'?: boolean
  'catalog:create'?: boolean
  'SecondaryCatalogTemplate:View'?: boolean
  'ProductTemplate:View'?: boolean
  'ProductTemplate:Unlock'?: boolean
  'ProductTemplate:Delete'?: boolean
  'File:Import'?: boolean
  'CatalogImportSFTPSettings:View'?: boolean
  'CatalogImportSFTPSettings:Edit'?: boolean
}

export const PERMISSIONS: PermissionsMap = {
  'B2B Order:Edit': false,
  'catalog:create': false,
  'SecondaryCatalogTemplate:View': false,
  'ProductTemplate:View': false,
  'ProductTemplate:Unlock': false,
  'ProductTemplate:Delete': false,
  'File:Import': false,
  'CatalogImportSFTPSettings:View': false,
  'CatalogImportSFTPSettings:Edit': false,
}

export type LegacyPermissions = { [key: string]: Array<string> }
