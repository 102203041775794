import { withErrorHandlers } from '@r1/http-client'
import { showNotification } from '../showNotification'
import { handleLogout } from './handleLogout'

/**
 * @description - Call if not valid refreshToken
 */
export const errorMiddleware = withErrorHandlers({
  '401': error => {
    handleLogout()
    // https://jira.trgdev.com/browse/R1S-7457 - A lot of error notifications in a row
    showNotification({
      level: 'warning',
      title: 'Your session expired',
      message: 'You will be redirected to login page',
    })
    return Promise.resolve(error)
  },
})
