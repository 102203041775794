import React, { useCallback, useEffect, useState } from 'react'
import {
  WireframeProvider,
  UserAccount,
  Logo,
  Divider,
  NavigationApiService,
  WarehousesApiService,
  TutorialsMenu,
  PrintLoginLabelModal,
} from '@r1/wireframe-primary'
import { Flex } from '@r1/ui-kit'
import { createBrowserHistory } from 'history'

import { SecondaryCatalog } from '@r1-oas/secondary-catalog-queries'
import { httpClient } from '../../../shared/lib/httpClient'
import { handleLogout } from '../../../shared/lib/httpClient/handleLogout'
import { SecondaryCatalogQueriesApi } from '../../../shared/api/SecondaryCatalogQueriesApi'
import { humanizeString } from '../../../shared/lib/humanizeString'
import {
  INavigationItemWithRequiredPermissions,
  INavigationApiServiceWithRequiredPermissions,
} from '../../../shared/api/NavigationApiMock'
import { useAccessControl } from '../../../shared/providers/PermissionsProvider'
import { isFergusonCatalogName } from '../../../shared/lib/isFergusonCatalogName'
import { MemberLogo, SettingsMenu, BackgroundTasksContainer, HelpMenu } from './NavigationHeader'

export const history = createBrowserHistory({ window })

const PAGE_PREFIX_URL = '/r1'

const renderHeaderLeftSide = () => (
  <Flex align="center">
    <Logo pagePrefixUrl={PAGE_PREFIX_URL} />
    <Divider ml="XS" mr="M" />
    <MemberLogo iconName="goTrgLight" />
  </Flex>
)

const renderHeaderRightSide = () => (
  <Flex align="center" spaceBetween="XS">
    <TutorialsMenu />
    <SettingsMenu />
    <BackgroundTasksContainer />
    <HelpMenu pagePrefixUrl={PAGE_PREFIX_URL} browserHistory={history} />
  </Flex>
)

const originalNavigationApi = new NavigationApiService(httpClient)

const PRODUCT_CATALOG_ITEM_ID = '8'
const SECONDARY_CATALOGS_ITEM_READONLY = {
  id: 'catalogs',
  parentId: PRODUCT_CATALOG_ITEM_ID,
  title: 'Catalogs',
  description: 'Main and secondary catalogs',
  isStarred: false,
}
const SECONDARY_CATALOGS_ITEM = {
  ...SECONDARY_CATALOGS_ITEM_READONLY,
  url: '/app/product-catalog/catalogs',
}

function generateSecondaryCatalogMenuSection(
  secondaryCatalog: SecondaryCatalog,
): Array<INavigationItemWithRequiredPermissions> {
  return [
    {
      id: secondaryCatalog.id,
      parentId: SECONDARY_CATALOGS_ITEM.id,
      title: humanizeString(secondaryCatalog.name),
      description: secondaryCatalog.name,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-families`,
      parentId: secondaryCatalog.id,
      title: 'Families',
      description: '',
      url: `/app/product-catalog/catalogs//${secondaryCatalog.id}/families`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-templates`,
      parentId: secondaryCatalog.id,
      title: 'Templates',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/templates`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-sftp-connections`,
      parentId: secondaryCatalog.id,
      title: 'SFTP connections',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/sftp-connections`,
      isStarred: false,
      permissions: ['CatalogImportSFTPSettings:View'],
    },
  ]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isFergusonOnly = ((FEATURE_FLAGS as string) || '').split(';').includes('IS_FERGUSON_ONLY')

const navigationApi: INavigationApiServiceWithRequiredPermissions = {
  getMenuItems: async (programId: number | null) => {
    const originalMenuItems = await originalNavigationApi.getMenuItems(programId)
    let secondaryCatalogsMenuItems: INavigationItemWithRequiredPermissions[] = []
    try {
      const response = await SecondaryCatalogQueriesApi.getSecondaryCatalogs()

      if (response.success) {
        if (isFergusonOnly) {
          const fergusonCatalog = response.body.find(({ name }) => isFergusonCatalogName(name))
          const fergusonCatalogSection = fergusonCatalog
            ? generateSecondaryCatalogMenuSection(fergusonCatalog)
            : []

          return originalMenuItems.concat([
            SECONDARY_CATALOGS_ITEM_READONLY,
            ...fergusonCatalogSection,
          ])
        }

        secondaryCatalogsMenuItems = response.body.reduce<INavigationItemWithRequiredPermissions[]>(
          (catalogs, secondaryCatalog) => {
            const secondaryCatalogSection = generateSecondaryCatalogMenuSection(secondaryCatalog)
            return [...catalogs, ...secondaryCatalogSection]
          },
          [],
        )
      }
    } catch (e) {
      secondaryCatalogsMenuItems = []
      console.info(e)
    }
    return originalMenuItems.concat([SECONDARY_CATALOGS_ITEM, ...secondaryCatalogsMenuItems])
  },
  addToStarred: originalNavigationApi.addToStarred,
  deleteFromStarred: originalNavigationApi.deleteFromStarred,
}

type UserManagementAccount = {
  id: string
  authInfo: {
    login: string
  }
  info: {
    fullName: string
    contactInfo: {
      email: string
    }
  }
}

const DEFAULT_USER_ACC: UserAccount = { id: 'User', login: 'User', fullName: 'User', email: '' }

export const WireframeProviderCreatorUnified = ({ children }: { children: React.ReactNode }) => {
  const allowedPermissions = useAccessControl()
  const [userAccount, setUserAccount] = useState<UserAccount>(DEFAULT_USER_ACC)
  const [printLoginLabelModal, setPrintLoginLabelModal] = useState<boolean>(false)
  const togglePrintLoginLabelModal = useCallback(() => setPrintLoginLabelModal(prev => !prev), [])

  const warehousesApi = new WarehousesApiService(httpClient)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse: UserManagementAccount = await httpClient.get(
          '/uisvc/user-management/users/current',
        )

        setUserAccount({
          id: userResponse.id,
          login: userResponse.authInfo.login,
          fullName: userResponse.info.fullName,
          email: userResponse.info.contactInfo.email,
        })
      } catch (e: unknown) {
        setUserAccount(DEFAULT_USER_ACC)
      }
    }

    fetchUser()
  }, [])

  const onLogout = useCallback(() => {
    handleLogout()
  }, [])

  const getMenuItems = async (programId: number | null) => {
    const result: INavigationItemWithRequiredPermissions[] = await navigationApi.getMenuItems(
      programId,
    )

    return result.filter(item => {
      // if menuItem permissions isn't set or empty - menu item will be rendered
      if (!item.permissions || !item.permissions.length) {
        return true
      }

      // if user has any of menuItem permission - menu item will be rendered
      return item.permissions.some(permission => allowedPermissions.includes(permission))
    })
  }

  return (
    <WireframeProvider
      key={allowedPermissions.length}
      withSearch="Pages"
      userAccount={userAccount}
      browserHistory={history}
      httpClient={httpClient}
      navigationApi={{ ...navigationApi, getMenuItems }}
      warehousesApi={warehousesApi}
      renderHeaderLeftSide={renderHeaderLeftSide}
      renderHeaderRightSide={renderHeaderRightSide}
      onLogout={onLogout}
    >
      {children}
      <PrintLoginLabelModal isOpen={printLoginLabelModal} onClose={togglePrintLoginLabelModal} />
    </WireframeProvider>
  )
}
