import React, { LazyExoticComponent } from 'react'
import {
  Route,
  Link as RouterLink,
  unstable_HistoryRouter as HistoryRouter,
  useNavigate,
  useLocation,
  useParams,
  generatePath,
  NavigateFunction,
} from 'react-router-dom'
import { Routes } from './Routes'

type RouteType = {
  path: string
  component: LazyExoticComponent<() => JSX.Element | null>
}

type RoutingProps = {
  routes: RouteType[]
}

const Routing = ({ routes }: RoutingProps) => (
  <Routes>
    {routes.map(({ path, component: Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ))}
  </Routes>
)

export {
  RouteType,
  Route,
  Routes,
  Routing,
  RouterLink,
  HistoryRouter,
  useLocation,
  useNavigate,
  useParams,
  generatePath,
}
export type { NavigateFunction }
