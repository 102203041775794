/* eslint-disable max-classes-per-file */

import { delay } from '../../lib/delay'

export type User = {
  id: string
  name: string
  email: string
}

export type SignInPayload = {
  email: string
  password: string
}

export class UserService {
  public static async getCurrentUser() {
    await delay(300)
    return {
      id: 'feature-sliced',
      name: 'Feature Sliced',
      email: 'featSliced@gotrg.com',
    }
  }
}

export class AuthService {
  public static async signIn(_: SignInPayload) {
    await delay(300)
  }

  public static async signOut() {
    await delay(100)
  }
}
