import { createEffect, createEvent } from 'effector-root'

const onRequestFail = createEvent<unknown>()

const createRequest = <Params, Done, Fail = unknown>(
  handler: (params: Params) => Done | Promise<Done>,
) => {
  const fx = createEffect<Params, Done, Fail>(handler)

  fx.failData.watch(onRequestFail)

  return fx
}

export { createRequest, onRequestFail }
