export const ROOT = '/app'
export const HOME_PAGE = ROOT

/**
 * Product Catalog
 */
export const PRODUCT_CATALOG = '/product-catalog'

/* Catalogs */
/* Paths for routes */
export const CATALOGS = '/catalogs'
/* Links for navigate  */
export const CATALOGS_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${CATALOGS}`

/* Catalogs */
/* Paths for routes */
export const TEMPLATES = `${CATALOGS}/:catalogId/templates`
export const MAIN_TEMPLATE_DETAILS = `${CATALOGS}/main/:catalogId/templates/:templateId`
export const MAIN_TEMPLATE_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${MAIN_TEMPLATE_DETAILS}`
export const SECONDARY_TEMPLATE_DETAILS = `${CATALOGS}/secondary/:catalogId/templates/:templateId`
export const TEMPLATES_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${TEMPLATES}`
export const DATA_ENRICHMENT_SETTING = `/main/data-enrichment`
export const DATA_ENRICHMENT_SETTING_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}/main/data-enrichment`
export const FILE_IMPORT_RESULTS_LINK = `${CATALOGS}/:catalogId/file-import-results`
export const FILE_IMPORT_RESULTS_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${FILE_IMPORT_RESULTS_LINK}`

/* Categories */
/* Paths for routes */
export const CATEGORIES = '/categories'
export const CATEGORY_CREATE = `${CATEGORIES}/new`
export const CATEGORY_DETAILS = `${CATEGORIES}/:categoryId`
/* Links for navigate  */
export const CATEGORIES_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${CATEGORIES}`
export const CATEGORY_CREATE_PAGE_LINK = `${CATEGORIES_PAGE_LINK}/new`

/* Attributes */
/* Paths for routes */
export const ATTRIBUTES = `/attributes`
export const ATTRIBUTE_CREATE = `${ATTRIBUTES}/new`
export const ATTRIBUTE_DETAILS = `${ATTRIBUTES}/:attributeId`
/* Links for navigate  */
export const ATTRIBUTES_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${ATTRIBUTES}`
export const ATTRIBUTE_CREATE_PAGE_LINK = `${ATTRIBUTES_PAGE_LINK}/new`

/* Families */
/* Paths for routes */
export const FAMILIES = `${CATALOGS}/:catalogId/families`
// export const FAMILIES = '/families'
export const FAMILIES_CREATE = `${FAMILIES}/new`
export const FAMILIES_DETAILS = `${FAMILIES}/:familyId`
/* Links for navigate */
export const FAMILIES_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${FAMILIES}`
export const FAMILIES_CREATE_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${FAMILIES_CREATE}`

/**
 * Sales
 */
export const SALES = '/sales'
/* Listings rules */
/* Paths for routes */
export const LISTING_RULES = '/listing-rules'
/* Links for navigate */
export const LISTING_RULES_PAGE_LINK = `${HOME_PAGE}${SALES}${LISTING_RULES}`

/* Pallets */
/* Paths for routes */
export const PALLETS = '/pallets'
/* Links for navigate */
export const PALLETS_PAGE_LINK = `${HOME_PAGE}${SALES}${PALLETS}`

/* Inventory */
/* Paths for routes */
export const INVENTORY = '/inventory'
/* Links for navigate */
export const INVENTORY_PAGE_LINK = `${HOME_PAGE}${SALES}${INVENTORY}`

/* Orders */
/* Paths for routes */
export const ORDERS = '/orders'
export const ORDER = `${ORDERS}/:orderId`
/* Links for navigate */
export const ORDER_PAGE_LINK = `${HOME_PAGE}${SALES}${ORDERS}`

/* Marketplace */
/* Paths for routes */
export const MARKETPLACE_ACCOUNTS = '/marketplace-accounts'
/* Links for navigate */
export const MARKETPLACE_ACCOUNTS_PAGE_LINK = `${HOME_PAGE}${SALES}${MARKETPLACE_ACCOUNTS}`

/**
 * Errors
 */
export const NOT_FOUND = '/not-found'
export const NOT_FOUND_PAGE_LINK = `${HOME_PAGE}${NOT_FOUND}`
export const ACCESS_DENIED = '/access-denied'
export const ACCESS_DENIED_PAGE_LINK = `${HOME_PAGE}${ACCESS_DENIED}`

/**
 * SiteMap
 */
export const SITE_MAP = '/sitemap'
export const SITE_MAP_PAGE_LINK = `${HOME_PAGE}${SITE_MAP}`

/**
 * SFTP connections
 */
/* Paths for routes */
export const SFTP_CONNECTIONS = `${CATALOGS}/:catalogId/sftp-connections`
/* Links for navigate  */
export const SFTP_CONNECTIONS_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${SFTP_CONNECTIONS}`

/* EDE Rules */
/* Paths for routes */
export const EXTERNAL_DATA_RULES = '/external-data-rules'
/* Links for navigate  */
export const EXTERNAL_DATA_RULES_PAGE_LINK = `${HOME_PAGE}${PRODUCT_CATALOG}${EXTERNAL_DATA_RULES}`
