import React, { useCallback } from 'react'
import { Link, H2 } from '@r1/ui-kit'
import { Main, ContentHeader } from '@r1/wireframe-primary'

import { useNavigate } from '../../../shared/lib/routing'
import { AccessDeniedSvg, CommonErrorContainer } from '../components'

export const AccessDeniedPage = () => {
  const navigate = useNavigate()
  const redirectToLastPage = useCallback(() => navigate(-1), [navigate])

  return (
    <Main fullScreen>
      <ContentHeader.Title browserTabTitle="403 Access Denied" />
      <CommonErrorContainer svg={<AccessDeniedSvg />}>
        <H2>
          You don&apos;t have permission to access this page.
          <br />
          <Link onClick={redirectToLastPage}>Go back</Link>
          &nbsp;to the previous page or use menu to navigate away.
        </H2>
      </CommonErrorContainer>
    </Main>
  )
}
