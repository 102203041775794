/* eslint-disable @typescript-eslint/ban-ts-comment, prefer-promise-reject-errors */
import { createClient } from '@r1/http-client'
import { iconsResponse } from '../../api/SvgStorage'
import { authMiddleware } from './authMiddleware'
import { errorMiddleware } from './errorMiddleware'

/**
 * @var httpClient is a http client for the new/thor packages: wireframe-primary.
 * This approach are implemented because there are errors with parsing error responses in the new modules.
 * It happens because new modules are used web-ui api under the hood  and web-ui map error response like this:
 * const mapToError = (err) => {
    const {
      status,
      data
    } = err.response
    return {
      status: status,
      body: data
    }
   }
 */

type ResponseError = {
  message?: string
  response?: {
    url?: string
    config?: {
      url?: string
    }
  }
}

const httpClient = createClient()(authMiddleware, errorMiddleware)

const headers = {
  'Content-Type': 'application/json',
}

httpClient.defaults.headers.post = headers
httpClient.defaults.headers.put = headers
httpClient.defaults.headers.common.Accept = 'application/json'

httpClient.interceptors.response.use(
  response => {
    // TODO: research why config transforms after access token is expired
    // @ts-ignore
    const url = response.config?.url ?? (response.url as unknown)
    if (
      // @ts-ignore
      window.APP_ENVIRONMENT === 'saas' &&
      (url as string)?.startsWith('/uisvc/user-management/')
    ) {
      console.log('MOCKED USER MANAGEMENT')
      return {}
    }

    return response.data as unknown
  },
  (error: ResponseError) => {
    // TODO: research why config transforms after access token is expired
    // @ts-ignore
    const url = error.response?.config?.url ?? error.response?.url
    // @ts-ignore
    if (window.APP_ENVIRONMENT === 'saas' && url?.startsWith('/uisvc/user-management/')) {
      console.log('MOCKED USER MANAGEMENT')
      return {}
    }
    if (error.response?.config?.url?.startsWith('/uisvc/svg-storage/api/')) {
      return iconsResponse
    }

    /**
     * In cases when some requests have been canceled (ex. an user close a page) errors of these requests won't have the response field
     * It causes errors during mapping errors objects in @r1-webui packages which are expected error.response field.
     */

    if (!error.response) {
      return Promise.reject({
        response: {
          status: 0,
          data: {
            message: error.message,
          },
        },
      })
    }

    return Promise.reject(error)
  },
)

export { httpClient }
