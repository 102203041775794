import React from 'react'
import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'
import { StyledContent } from '../StatusCodesStyled'

type Props = {
  svg: React.ReactNode
  children: React.ReactNode
}

const RelativeContent = styled(StyledContent)`
  position: relative;
`

const DecriptionContainer = styled(Flex)`
  z-index: 1;
`

const SvgContainer = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const CommonErrorContainer = ({ svg, children }: Props) => (
  <RelativeContent>
    <DecriptionContainer column mt="XXL">
      {children}
    </DecriptionContainer>
    <SvgContainer>{svg}</SvgContainer>
  </RelativeContent>
)
