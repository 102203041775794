import React from 'react'
// eslint-disable-next-line import/no-unresolved
import { LinkProps } from '@r1/ui-kit/contracts/ts/Link'
import { isRouterLink } from '../../../shared/lib/isRouterLink'
import { RouterLink } from '../../../shared/lib/routing'

const UniversalRouterLink = React.memo(function UniversalRouterLink(props: LinkProps) {
  if (props.href) {
    if (isRouterLink(props.href)) {
      return <RouterLink to={props.href} {...props} />
    }
    return <a {...props} />
  }
  if (props.to) {
    if (isRouterLink(props.to)) {
      return <RouterLink {...props} to={props.to} />
    }
    const { to, ...otherProps } = props
    return <a href={to} {...otherProps} />
  }
  return <span {...props} />
})

export { UniversalRouterLink }
