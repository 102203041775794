import { LegacyPermissions } from './permissions'

const ENTITIES_ACTIONS_KEY = 'Coreblocks:EntitiesActions'

export const getLegacyPermissions = (): LegacyPermissions => {
  const jsonData = window.localStorage.getItem(ENTITIES_ACTIONS_KEY)
  let permission = {}
  if (jsonData) {
    try {
      permission = JSON.parse(jsonData) as LegacyPermissions
    } catch {
      permission = {}
    }
  }
  return permission
}
